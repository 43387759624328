import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MenuDrawer.sass";

class MenuDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classname: "close",
        };
        this.classname = this.props.className;
    }

    shouldComponentUpdate(nextProps) {
        this.classname = nextProps.className;
        if (nextProps.className === "close" && this.state.classname === "open") {
            this.classname = "open closing";
            setTimeout(() => {
                this.setState({
                    classname: "close",
                });
            }, 200);
        } else if (nextProps.className === "open" && this.state.classname === "close") {
            this.setState({
                classname: nextProps.className,
            });
        } else {
            this.classname = this.state.classname;
        }
        return true;
    }

    getLinks() {
        return this.props.pages.reduce((acc, e, i) => {
            acc.push(
                <Link key={i} className={this.props.pathName === e.url ? "active" : ""} to={e.url}>
                    <FontAwesomeIcon icon={e.icon} /> {e.name}
                    {/* LINK STRING FOR INTERNATIONALIZATION  */}
                </Link>,
            );
            if (Reflect.has(e, "subPages")) {
                const subPages = e.subPages;
                subPages.forEach((subPage, index) => {
                    acc.push(
                        <Link
                            key={`subpage-${index}`}
                            className={this.props.pathName === subPage.url ? "active" : ""}
                            to={Array.isArray(subPage.url) ? subPage.url[0] : subPage.url}
                        >
                            <FontAwesomeIcon icon={subPage.icon} /> {subPage.name}
                            {/* LINK STRING FOR INTERNATIONALIZATION  */}
                        </Link>,
                    );
                });
            }
            return acc;
        }, []);
    }

    render() {
        return (
            <div
                id="menuDrawer"
                className={this.classname ? this.classname : ""}
                // {...(this.props.navBackButton
                //     ? {
                //           style: {
                //               marginTop: "50px",
                //               height: "calc(100vh - 50px)",
                //           },
                //       }
                //     : {})}
            >
                <div id="drawerMenu">{this.getLinks()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pathName: state.router.location.pathname,
    navBackButton: state.navBackButton,
});
const connectedMenuDrawer = connect(mapStateToProps)(MenuDrawer);
export { connectedMenuDrawer as MenuDrawer };
