import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import "./index.scss";
// Components
import { Header, Footer, Head, Loading } from "./Components";

const NotFound = lazy(() => import("./Pages/NotFound.jsx"));
const Main = lazy(() => import("./Pages/Main.jsx"));
const Projeler = lazy(() => import("./Pages/Projeler.jsx"));

const scrollToTop = () => window.scrollTo(0, 0);
const RouteComponent = ({ name, url, head = true, Component }) => (
    <Route
        exact
        key={name}
        path={url}
        component={(rootComponentprops) => (
            <section className={(Array.isArray(url) ? url[0] : url).replace("/", "")}>
                {head && <Head />}
                <Suspense fallback={<Loading eye={true} />}>
                    <Component {...rootComponentprops} />
                </Suspense>
            </section>
        )}
    />
);

const Root = React.memo(function Root(props) {
    // her url degisiminde sayfa yukari cekilsin scroll sifirlansin diye
    React.useEffect(() => {
        const unlisten = props.history.listen((location, action) => {
            scrollToTop();
        });
        return () => unlisten();
    }, [props.history]);

    return (
        <ConnectedRouter history={props.history}>
            <>
                <Header history={props.history} menuStyle={props.screenSizes.menuStyle} />
                <main className={"main"}>
                    <Switch>
                        {RouteComponent({
                            name: "Ana Sayfa",
                            url: "/",
                            Component: Main,
                        })}
                        {RouteComponent({
                            name: "Projeler",
                            url: "/projeler",
                            Component: Projeler,
                        })}
                        <Route
                            component={() => (
                                <Suspense fallback={<Loading eye={true} />}>
                                    <NotFound />
                                </Suspense>
                            )}
                        />
                    </Switch>
                </main>
                <Footer />
            </>
        </ConnectedRouter>
    );
});

const mapStateToProps = (state) => ({
    screenSizes: state.screenSizes,
    standalone: state.standalone,
    mobileMenuOpen: state.isMenuActive,
});

export default connect(mapStateToProps)(Root);
