import React from 'react';
import './StatusBar.sass';

const StatusBar = React.memo(
    function StatusBar(props) {
        return props.standalone
        ? <div key='statusbar' id='statusbar'/>
        : null;
    }
)

export {
    StatusBar
}
