import { SCREEN_SIZE_CHANGED, ActionTypes } from "../Actions";
import { Reducer } from "redux";

interface ScreenSizeState {
    width: number;
    height: number;
    menuStyle: "mobil" | "full";
}

const initialScreeSizeVariables: ScreenSizeState = {
    width: window.innerWidth,
    height: window.innerHeight,
    menuStyle:
        /*( window.innerWidth > 992 ) ? (navigator.standalone? 'mobil':'full') : */ "mobil",
};

export const SCREEN_SIZE_CHANGED_REDUCER: Reducer<
    ScreenSizeState,
    ActionTypes
> = (state = initialScreeSizeVariables, action) => {
    switch (action.type) {
        case SCREEN_SIZE_CHANGED:
            return {
                width: action.width,
                height: action.height,
                menuStyle: action.menuStyle,
            };
        default:
            return state;
    }
};
