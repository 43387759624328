import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import "./FooterMob.sass";

const FooterMob = React.memo(function FooterMob(props: any) {
    // const [count, setCount] = React.useState(0);
    const pathUrl = props.pathname.replace("index.html", "");
    console.log(props.pages);
    return (
        <div id="footerMob">
            <nav id="footerMenuMob">
                {props.pages.map((e: { url: string; name: string; icon: any; subPages: any }, i: number) =>
                    [
                        <Link key={`${pathUrl}-${i}`} className={pathUrl === e.url ? "active" : ""} to={e.url}>
                            <div className="menuItem" key={e.name}>
                                <FontAwesomeIcon icon={e.icon} />
                                <span>{e.name}</span>
                            </div>
                        </Link>,
                    ].concat(
                        e.subPages.map((e: { url: string; name: string; icon: any; subPages: any }, i: number) => [
                            <Link key={`${pathUrl}-${i}`} className={pathUrl === e.url ? "active" : ""} to={e.url}>
                                <div className="menuItem" key={e.name}>
                                    <FontAwesomeIcon icon={e.icon} />
                                    <span>{e.name}</span>
                                </div>
                            </Link>,
                        ]),
                    ),
                )}
            </nav>
        </div>
    );
});

const mapStateToProps = (state: any) => ({
    pathname: state.router.location.pathname,
});

const connectedFooterMod = connect(mapStateToProps)(FooterMob);

export { connectedFooterMod as FooterMob };
