import React, { Suspense } from "react";
import * as serviceWorker from "./service-Worker";

// Redux connected-router-redux bindings
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";

import { Provider } from "react-redux";

// Root Component
import Root from "./Root.jsx";
import { Loading } from "./Components";

// Root Reducer
import createRootReducer from "./Redux/Reducers";

import { routerMiddleware } from "connected-react-router";

// Initialize Redux dependencies and initial state
const history = createBrowserHistory();
const composeEnhancer =
    process.env.NODE_ENV === "production" ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    createRootReducer(history), // new root reducer with router state
    {}, // initial state
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            // ... other middlewares ...
        ),
    ),
);

const Main = () => (
    <Provider store={store}>
        <Suspense fallback={<Loading />}>
            <Root history={history} />
        </Suspense>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

export default Main;
