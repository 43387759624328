import React from "react";
import { IMG } from "..";
import "./PhotoContainer.sass";

const PhotoContainer = React.memo(function PhotoContainer(props: {
    srcwebp?: string;
    src: string | JSX.Element;
    alt?: string;
}) {
    return (
        <div className={"photoContainer"}>
            <IMG
                srcwebp={props.srcwebp}
                src={props.src}
                alt={props.alt || "test"}
            />
            {/* <p>{props.text || 'Item TEST'}</p> */}
        </div>
    );
});

export { PhotoContainer };
