import React from "react";
import { connect } from "react-redux";
// Utils
import { canUseWebP } from "../utils";
// Components
import { Loading } from "..";
// Actions
import { imgLoadedActionCreator } from "../../Redux/ActionCreators";

interface IIMG {
    [key: string]: any;
}

const loadImg = (src: string, cb: (src: string) => void, delay: number = 0, delayCB: () => void = () => {}) => {
    const dl = setTimeout(() => {
        delayCB();
    }, delay);
    const img = new Image();
    img.onload = () => {
        clearTimeout(dl);
        cb(src);
    };
    img.src = src;
};

const IMG = React.memo(function IMG(props: IIMG) {
    const { src, srcwebp, alt, onImgLoaded, loadedImgSrc, ...others } = props;
    const [loadingState, setLoadingState] = React.useState(false);
    React.useEffect(() => {
        canUseWebP() && srcwebp
            ? !loadedImgSrc.includes(srcwebp) &&
              loadImg(srcwebp, onImgLoaded, 1000, () => {
                  setLoadingState(true);
              })
            : !loadedImgSrc.includes(src) &&
              loadImg(src, onImgLoaded, 1000, () => {
                  setLoadingState(true);
              });
    });

    return loadedImgSrc.includes(src) || loadedImgSrc.includes(srcwebp) ? (
        <picture>
            {srcwebp && <source srcSet={srcwebp} type="image/webp" />}
            <img src={src} alt={alt} {...others} />
        </picture>
    ) : loadingState ? (
        <Loading />
    ) : (
        <picture>
            {srcwebp && <source srcSet={srcwebp} type="image/webp" />}
            <img src={src} alt={alt} {...others} />
        </picture>
    );
});

const mapStateToProps = (state: any) => ({
    loadedImgSrc: state.LoadedImgList,
});

const mapDispatchToProps = (dispatch: any) => ({
    onImgLoaded: (src: string) => {
        dispatch(imgLoadedActionCreator(src));
    },
});
const connectedIMG = connect(mapStateToProps, mapDispatchToProps)(IMG);
export { connectedIMG as IMG };
