import React from "react";
import "./Signature.sass";
import { IMG } from "..";
import ben from "../../images/webp/kare.webp";
import benJpg from "../../images/kare.jpg";

const Signature = React.memo(function Signature(props) {
    return (
        <div className={`signitureBox`} {...props}>
            <div className={"signitureBoxAvatar"}>
                <IMG src={benJpg} srcwebp={ben} width={"100%"} alt={"Orkun Tümer"} />
            </div>
            <div className={`signitureBoxInfo`}>
                <div className={"signitureBoxInfoName"}>Orkun Tümer</div>
                <div className={"signitureBoxInfoTitle"}>Sr. Software Engineer at Turk Telekom Ar-Ge</div>
            </div>
        </div>
    );
});

export { Signature };
