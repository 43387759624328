import React, { Component } from 'react';
import './MenuButton.sass';

class MenuButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      action: false,
      status: false,
      time: new Date()
    }
  }

  componentDidMount() {
    if (this.props.menuOpen) {
      this.setState({ action: true, status: true, time: new Date() });
    }
  }

  render() {
    let sinif = (this.props.className) ? `${this.props.className} menuBtn` : 'menuBtn';
    if (this.state.action === true && this.state.status === true) {
        sinif += ' kapat';
    } else if (this.state.action === true && this.state.status === false) {
        sinif += ' kapaniyor';
        setTimeout(() => { this.setState({ action: false }) }, 200)
    }
    return (
      <div  className={ sinif }
            onClick={ 
              () => {
                if ((new Date() - this.state.time) > 200) {
                  this.props.menuHandler && this.props.menuHandler(!this.state.status);
                  this.setState({ action: true, status: !this.state.status, time: new Date() });
                }
              }
            }>
        <span className="bar"/>
        <span className="bar"/>
        <span className="bar"/>
      </div>
    );
  }
}

export {
  MenuButton
};
