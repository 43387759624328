import { BRICKS_COLUMNS_CHANGED, ActionTypes } from "../Actions";
import { Reducer } from "redux";

const initialColumnCountsState = [
    {
        size: 2400,
        count: 5,
    },
    {
        size: 1600,
        count: 4,
    },
    {
        size: 992,
        count: 3,
    },
    {
        size: 750,
        count: 2,
    },
    {
        size: 450,
        count: 1,
    },
    {
        size: 0,
        count: 1,
    },
];

export const bricks: Reducer<
    Array<{
        size: number;
        count: number;
    }>,
    ActionTypes
> = (state = initialColumnCountsState, action) => {
    switch (action.type) {
        case BRICKS_COLUMNS_CHANGED:
            return action.counts;
        default:
            return state;
    }
};
