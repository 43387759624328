import React from "react";
import { connect } from "react-redux";
import "./loading.sass";

const Loading = React.memo(function Loading(props: { color?: string; eye?: boolean }) {
    return (
        <div className="loading">
            <div>
                <svg
                    className={"left"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    stroke={props.color || "#767676"}
                >
                    <circle cx={15} cy={15} r={12} fill={"none"} />
                    <circle stroke={"none"} cx={11} cy={11} r={4} />
                </svg>
                {props.eye ? (
                    <svg
                        className={"right"}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                        stroke={props.color || "#767676"}
                    >
                        <circle cx={15} cy={15} r={12} fill={"none"} />
                        <circle stroke={"none"} cx={11} cy={11} r={4} />
                    </svg>
                ) : null}
            </div>
        </div>
    );
});

const mapStateToProps = (state: { LoadingColor: string }) => ({
    color: state.LoadingColor,
});
const connectedLoading = connect(mapStateToProps)(Loading);
export { connectedLoading as Loading };
