/**
 * ACTION TAGS
 */
export const MENU_TOGGLE_REQUESTED: "MENU_TOGGLE_REQUESTED" =
    "MENU_TOGGLE_REQUESTED";
export const SCREEN_SIZE_CHANGED: "SCREEN_SIZE_CHANGED" = "SCREEN_SIZE_CHANGED";
export const BRICKS_COLUMNS_CHANGED: "BRICKS_COLUMNS_CHANGED" =
    "BRICKS_COLUMNS_CHANGED";
export const IMG_LOADED: "IMG_LOADED" = "IMG_LOADED";
export const NAV_BACK_BUTTON_SHOW: "NAV_BACK_BUTTON_SHOW" =
    "NAV_BACK_BUTTON_SHOW";
export const NAV_BACK_BUTTON_HIDE: "NAV_BACK_BUTTON_HIDE" =
    "NAV_BACK_BUTTON_HIDE";
export const NAV_BACK_BUTTON_TOGGLE: "NAV_BACK_BUTTON_TOGGLE" =
    "NAV_BACK_BUTTON_TOGGLE";

export type ActionTagTypes =
    | typeof MENU_TOGGLE_REQUESTED
    | typeof SCREEN_SIZE_CHANGED
    | typeof BRICKS_COLUMNS_CHANGED
    | typeof IMG_LOADED
    | typeof NAV_BACK_BUTTON_SHOW
    | typeof NAV_BACK_BUTTON_HIDE;

export const ActionTags = {
    MENU_TOGGLE_REQUESTED,
    SCREEN_SIZE_CHANGED,
    BRICKS_COLUMNS_CHANGED,
    IMG_LOADED,
    NAV_BACK_BUTTON_SHOW,
    NAV_BACK_BUTTON_HIDE,
    NAV_BACK_BUTTON_TOGGLE,
};

/**
 * ACTIONS
 */
export type MENU_TOGGLE_REQUESTED_ACTION = {
    type: typeof MENU_TOGGLE_REQUESTED;
};
export type SCREEN_SIZE_CHANGED_ACTION = {
    type: typeof SCREEN_SIZE_CHANGED;
    width: number;
    height: number;
    menuStyle: "mobil" | "full";
};
export type BRICKS_COLUMNS_CHANGED_ACTION = {
    type: typeof BRICKS_COLUMNS_CHANGED;
    counts: Array<{
        size: number;
        count: number;
    }>;
};
export type IMG_LOADED_ACTION = {
    type: typeof IMG_LOADED;
    src: string;
};
export type NAV_BACK_BUTTON_SHOW_ACTION = {
    type: typeof NAV_BACK_BUTTON_SHOW;
};
export type NAV_BACK_BUTTON_HIDE_ACTION = {
    type: typeof NAV_BACK_BUTTON_HIDE;
};
export type NAV_BACK_BUTTON_TOGGLE_ACTION = {
    type: typeof NAV_BACK_BUTTON_TOGGLE;
};

export type ActionTypes =
    | MENU_TOGGLE_REQUESTED_ACTION
    | SCREEN_SIZE_CHANGED_ACTION
    | BRICKS_COLUMNS_CHANGED_ACTION
    | IMG_LOADED_ACTION
    | NAV_BACK_BUTTON_SHOW_ACTION
    | NAV_BACK_BUTTON_HIDE_ACTION
    | NAV_BACK_BUTTON_TOGGLE_ACTION;
