import React from "react";
import "./head.scss";
import { IMG } from "..";
import selfPhoto from "../../images/ben.jpg";
import selfPhotoWebp from "../../images/webp/ben.webp";
import { Link } from "react-router-dom";

const Head = React.memo(function Head() {
  return (
    <section className="head-container">
      <Link to="/">
        <IMG src={selfPhoto} srcwebp={selfPhotoWebp} alt="Orkun Tümer" />
      </Link>
      <h1 className="head-name">Orkun Tümer</h1>
      <h2 className="head-title">
        Senior Frontend Engineer at{" "}
        <span>
          <a href="https://www.fireflyon.com">Firefly</a>
        </span>
      </h2>
      <hr />
    </section>
  );
});

export { Head };
