import { MENU_TOGGLE_REQUESTED, ActionTypes } from "../Actions";

// for state its important to have initial default value
export const MENU_TOGGLE_REQUESTED_REDUCER = (
    state = false,
    action: ActionTypes,
): boolean => {
    switch (action.type) {
        case MENU_TOGGLE_REQUESTED:
            return !state;
        default:
            return state;
    }
};
