import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// Reducers
import { MENU_TOGGLE_REQUESTED_REDUCER } from "./menuButton";
import { standalone } from "./standalone";
import { SCREEN_SIZE_CHANGED_REDUCER } from "./SCREEN_SIZE_CHANGED_REDUCER";
import { LoadingColor } from "./LoadingColor";
import { bricks } from "./bricks";
import { IMG_LOADED_REDUCER } from "./LoadedImgList";
import { NAV_BACK_BUTTON_REDUCER } from "./NAV_BACK_BUTTON_REDUCER";
import { History } from "history";

export default (history: History) =>
    combineReducers({
        router: connectRouter(history),
        isMenuActive: MENU_TOGGLE_REQUESTED_REDUCER,
        standalone,
        screenSizes: SCREEN_SIZE_CHANGED_REDUCER,
        LoadingColor,
        columnCounts: bricks,
        LoadedImgList: IMG_LOADED_REDUCER,
        navBackButton: NAV_BACK_BUTTON_REDUCER,
    });
