import { disableReactDevTools } from "./disable-react-dev-tools";
import React from "react";
import ReactDOM from "react-dom";
import App from "./main.react";
// initialize react app
async function initApp() {
    ReactDOM.render(<App />, document.getElementById("to"));
    if (window.location.pathname.includes("index.html")) {
        window.location.pathname = "/";
    }
}
disableReactDevTools();
initApp();
