import {
    NAV_BACK_BUTTON_HIDE,
    NAV_BACK_BUTTON_SHOW,
    ActionTypes,
} from "../Actions";
import { Reducer } from "redux";

export const NAV_BACK_BUTTON_REDUCER: Reducer<boolean, ActionTypes> = (
    state = false,
    action,
) => {
    switch (action.type) {
        case NAV_BACK_BUTTON_HIDE:
            return false;
        case NAV_BACK_BUTTON_SHOW:
            return true;
        default:
            return state;
    }
};
