import { ActionTags } from "../Actions";

export const MenuToggleRequestedActionCreator = () => ({
    type: ActionTags.MENU_TOGGLE_REQUESTED,
});

export const ScreenSizeChangedActionCreator = (
    width: number,
    height: number,
    menuStyle: "mobil" | "full",
) => ({
    type: ActionTags.SCREEN_SIZE_CHANGED,
    width: width,
    height: height,
    menuStyle: menuStyle,
});

export const bricksColumnChangeAC = (
    newColumnCount: Array<{ size: number; count: number }>,
) => ({
    type: ActionTags.BRICKS_COLUMNS_CHANGED,
    columnCount: newColumnCount,
});

export const imgLoadedActionCreator = (imgSrc: string) => ({
    type: ActionTags.IMG_LOADED,
    src: imgSrc,
});

export const navBackButtonShow = () => ({
    type: ActionTags.NAV_BACK_BUTTON_SHOW,
    bool: true,
});

export const navBackButtonHide = () => ({
    type: ActionTags.NAV_BACK_BUTTON_HIDE,
    bool: false,
});
