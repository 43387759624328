import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./Header.sass";

// Components
import { StatusBar, MenuDrawer, MenuButton } from "../";

import { faAngleLeft as backIcon } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Actions
import { MenuToggleRequestedActionCreator } from "../../Redux/ActionCreators";

class Header extends Component {
    getFullMenu() {
        return this.props.pages.map((e, i) => {
            return (
                <Link className={this.props.pathName === e.url ? "active" : ""} key={i} to={e.url}>
                    {e.name}
                    {/* LINK STRING FOR INTERNATIONALIZATION  */}
                </Link>
            );
        });
    }

    getMobilMenu() {
        return (
            <>
                <MenuButton
                    menuOpen={this.props.mobileMenuOpen}
                    className={`menuButton`}
                    menuHandler={this.props.onMenuButtonClicked}
                />
                <MenuDrawer pages={this.props.pages} className={this.props.mobileMenuOpen ? "open" : "close"} />
            </>
        );
    }

    go(param) {
        console.log(param);
        this.props.history.push(`/${param}`);
    }

    render() {
        const headerClass = this.props.standalone ? "standalone" : "";
        // const menuRender = this.props.standaloneı
        //     ? null // if
        //     : this.props.menuStyle === "full"
        //     ? this.getFullMenu() // else if
        //     : this.getMobilMenu(); // else

        const backButton = this.props.navBackButton;
        // const l = this.props.history.location.pathname.split("/");
        return (
            <header className={`${this.props.menuStyle}${headerClass ? ` ${headerClass}` : ""}`}>
                <StatusBar standalone={this.props.standalone} />
                <nav id="menu">
                    {this.props.standalone && backButton ? (
                        <button className={"backButton"} onClick={() => this.props.history.goBack()}>
                            <FontAwesomeIcon icon={backIcon} />
                            <span
                                style={{
                                    textTransform: "capitalize",
                                }}
                            >
                                Blog
                            </span>
                        </button>
                    ) : null}
                    {/* <div id={"menudiv"}>{menuRender}</div> */}
                    <section id="links">
                        {this.props.pathName === "/" ? null : (
                            <Link to="/" title="Ana Sayfa">
                                Ana Sayfa
                            </Link>
                        )}
                        <a href="https://www.patreon.com/tumerorkun" title="Orkun Tümer Patreon Sayfası">
                            Patreon
                        </a>
                        <a href="https://www.github.com/tumerorkun" title="Orkun Tümer Github Sayfası">
                            GitHub
                        </a>
                    </section>
                </nav>
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    standalone: state.standalone,
    mobileMenuOpen: state.isMenuActive,
    navBackButton: state.navBackButton,
    pathName: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
    onMenuButtonClicked: () => {
        dispatch(MenuToggleRequestedActionCreator());
    },
});
const connectedHead = connect(mapStateToProps, mapDispatchToProps)(Header);
export { connectedHead as Header };
