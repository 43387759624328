import React, { Component } from "react";
import { connect } from "react-redux";
import "./Bricks.scss";
// Actions
import { imgLoadedActionCreator } from "../../Redux/ActionCreators";
import { PhotoContainer } from "..";

interface IBricks {
    [key: string]: any;
}

const calcColumns = (propsColumnCounts: [{ size: number; count: number }], propsWindowWidth: number) =>
    propsColumnCounts
        .sort((prev, now) => (now.size > prev.size ? 1 : now.size < prev.size ? -1 : 0))
        .reduce(
            (prev: { size: number; count: number }, now) =>
                now.size < prev.size && propsWindowWidth >= prev.size ? prev : now,
            { size: 0, count: 1 },
        ).count;

class Bricks extends Component<IBricks> {
    photoCount: number;
    columnCount: number;
    oldColumnCount: number;
    columns: Array<Array<JSX.Element>>;
    columnsHeights: Array<number>;
    minimumColumnIndex: number;
    loadedImgs: Array<{ src: string; width: number; height: number }>;
    constructor(props: IBricks) {
        super(props);
        this.photoCount = 0;
        this.columnCount = calcColumns(this.props.columnCounts, this.props.windowWidth);
        this.oldColumnCount = this.columnCount;
        this.columns = new Array(this.columnCount).fill([]).map((e) => [...e]);
        this.columnsHeights = new Array(this.columnCount).fill(0);
        this.minimumColumnIndex = 0;
        this.loadedImgs = [];

        props.photoSrcs.forEach((src: string, index: number) => {
            if (!this.loadedImgs.find((img) => img.src === src)) {
                const img = new Image();
                img.onload = () => {
                    props.onImgLoaded(src);
                    this.loadedImgs.push({
                        src: src,
                        width: img.width,
                        height: Math.round(
                            img.height /
                                (img.width / ((this.props.windowWidth - this.columnCount * 10) / this.columnCount)),
                        ),
                    });
                    this.loadImg(
                        src,
                        index,
                        Math.round(
                            img.height /
                                (img.width / ((this.props.windowWidth - this.columnCount * 10) / this.columnCount)),
                        ),
                    );
                };
                img.src = src;
            } else {
                this.loadImg(src, index, this.loadedImgs.find((img) => img.src === src)!.height);
            }
        });
    }

    shouldComponentUpdate(nextProps: IBricks /*, nextState*/) {
        const test = this.reCalcColumnCount(nextProps.columnCounts, nextProps.windowWidth);
        return (
            nextProps.children.length !== (Array.isArray(this.props.children) && this.props.children!.length) || test
        );
    }
    componentDidUpdate() {
        if (this.oldColumnCount !== this.columnCount) {
            this.props.photoSrcs.forEach((src: string, index: number) => {
                this.loadImg(src, index, this.loadedImgs.find((img) => img.src === src)!.height);
            });
            this.oldColumnCount = this.columnCount;
        }
    }

    render() {
        return (
            <div id={"bricks"} style={{ visibility: "visible" }}>
                {this.columns.map((column, index) => (
                    <div key={`bricks-column-${index}`} className={"bricks-column"}>
                        {column.map((item, itemIndex) => {
                            return (
                                <div key={`${item}-container`} className={"bricks-item-container"}>
                                    <div key={`${item}`} className={"bricks-item"}>
                                        <PhotoContainer key={`${item}-photo-container`} src={item} />
                                        {/* <div
                                            style={{
                                                width: "100%",
                                                height: "20px",
                                                textAlign: "right",
                                                fontSize: "15px",
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                ...
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }

    reCalcColumnCount = (propsColumnCounts: any, propsWindowWidth: number) => {
        const nextColumnCount = calcColumns(propsColumnCounts, propsWindowWidth);
        if (nextColumnCount !== this.columnCount) {
            this.columnCount = nextColumnCount;
            this.columns = new Array(nextColumnCount).fill([]).map((e) => [...e]);
            this.columnsHeights = new Array(this.columnCount).fill(0);
            this.photoCount = 0;
            return true;
        }
        return false;
    };

    loadImg = (src: string, index: number, imgHeight: number) => {
        setTimeout(() => {
            this.minimumColumnIndex = this.columnsHeights.indexOf(Math.min(...this.columnsHeights));
            this.columnsHeights[this.minimumColumnIndex] += imgHeight;
            if (this.photoCount < this.props.photoSrcs.length) {
                this.columns[this.minimumColumnIndex].push(this.props.photoSrcs[index]);
                this.photoCount += 1;
                this.forceUpdate();
            }
        }, 50);
    };
}

const mapDispatchToProps = (dispatch: any) => ({
    onImgLoaded: (src: string) => {
        dispatch(imgLoadedActionCreator(src));
    },
});

const mapStateToProps = (state: any) => ({
    // loadedImgSrc: state.ImgLoaded,
    windowWidth: state.screenSizes.width,
    columnCounts: state.columnCounts,
});

const connectedBricks = connect(mapStateToProps, mapDispatchToProps)(Bricks);
export { connectedBricks as Bricks };
