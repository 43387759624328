export function getScreenSize() {
    let w = 0;
    !("innerWidth" in window)
        ? (w = (document.documentElement || document.body)["clientWidth"])
        : (w = window.innerWidth);
    let h = 0;
    !("innerHeight" in window)
        ? (h = (document.documentElement || document.body)["clientHeight"])
        : (h = window.innerHeight);
    return { width: w, height: h };
}

export function isIphoneX() {
    return new Promise((res, rej) => {
        setTimeout(() => {
            let iOS = /iPhone/.test(navigator.userAgent);
            let max = Math.max(window.innerWidth, window.innerHeight);
            if (iOS && max === 812) {
                res(true);
            } else {
                rej();
            }
        }, 100);
    });
}

export function getPropIfExists(e: any, _: { props: any }, propName: string, handler: Function) {
    return typeof _.props[propName] !== "undefined" ? _.props[propName](e) : handler(e);
}

let canUseWebPFlag: null | boolean = null;
export function canUseWebP() {
    if (canUseWebPFlag !== null) {
        return canUseWebPFlag;
    } else {
        const elem = document.createElement("canvas");
        if (!!(elem.getContext && elem.getContext("2d"))) {
            return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
        }
        return false;
    }
}
