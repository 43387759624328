import React from "react";
import "./Footer.sass";

const Footer = React.memo(function Footer() {
    return (
        <footer>
            <span id="copyright">&copy; Copyright 2020, Orkun Tümer</span>
        </footer>
    );
});

export { Footer };
