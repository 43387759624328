import React from "react";
import "./Box.scss";
import { IMG } from "..";

interface IBox {
    [key: string]: any;
}

const Box = React.memo(function Box(props: IBox) {
    const { className, img, imgwebp, imgAlt, title, date, imgSquare, size, big, ...others } = props;

    React.useLayoutEffect(() => {
        const root = document.documentElement;
        root.style.setProperty("--box-size", `${size ? size : 70}px`);
    });

    return (
        <div
            className={`containerBox${img ? "" : " noImg"}${date ? "" : " noDate"}${className ? ` ${className}` : ""}`}
            {...others}
        >
            {img ? (
                <div className={`boxIcon${imgSquare ? " square" : ""}`}>
                    <IMG srcwebp={imgwebp} src={img} width={"100%"} alt={imgAlt || "box-image"} />
                </div>
            ) : null}
            <div className={`boxInfo`}>
                {date ? <div className={"boxInfoDate"}>{date}</div> : null}
                {title ? <div className={"boxInfoTitle"}>{title}</div> : null}
                <div className={"boxInfoDetail"}>{props.children}</div>
            </div>
        </div>
    );
});

export { Box };
