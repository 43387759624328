import { IMG_LOADED, ActionTypes } from "../Actions";
import { Reducer } from "redux";

export const IMG_LOADED_REDUCER: Reducer<string[], ActionTypes> = (
    state = [],
    action,
) => {
    switch (action.type) {
        case IMG_LOADED:
            return [...state, action.src];
        default:
            return state;
    }
};
